import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import slugify from 'slugify';

function Book({ cursor, ...book }) {
  const addHttps = (url) =>
    url.padStart(url.length + 'https:'.length, 'https:');

  const imagePath = addHttps(book.thumbnail.fields.file.url);
  const slug = slugify(book.title, { lower: true }); // my-book-title

  const isSignedUp = localStorage.getItem('theolitaguide__signedup') === 'true';

  const pdfUrl = addHttps(book.bookPdf.fields.file.url);

  return (
    <Container to={isSignedUp ? pdfUrl : `/signup/${slug}`} cursor={cursor}>
      {/* <GatsbyImage loading="eager" image={image} alt={book.title} /> */}

      <Img src={imagePath} alt={book.title} />
    </Container>
  );
}

const Container = styled(Link)`
  display: block;
  border: 0.2px solid rgba(255, 255, 255, 0.2);
  cursor: ${({ cursor }) =>
    cursor === 'pointer' ? 'pointer' : 'move'}; //fallback
  cursor: ${({ cursor }) => cursor};
`;

const Img = styled.img`
  width: 100%;
  display: block;
`;

export default Book;
