import React from 'react';
import BooksCarousel from './BooksCarousel';

function Hero({ backgroundColor}) {
  return (
    <section className="first" style={{ backgroundColor }}>
      <img id="logosize" src="./assets/logo_white.png" alt="logo" />
      <BooksCarousel  />
    </section>
  );
}

export default Hero;
