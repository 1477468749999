import React from 'react';

function BodyParagraphs({ backgroundColor, nodes }) {
  return (
    <section className="body-copy" style={{ backgroundColor }}>
      <div id="copy">
        {nodes.map(({ id, textContent }) => {
          return <p key={id}>{textContent}</p>;
        })}
      </div>
    </section>
  );
}

export default BodyParagraphs;
