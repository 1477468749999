import React, { useEffect, useState } from 'react';
import Book from './Book';
import { createClient } from 'contentful';
import { Fade } from 'react-awesome-reveal';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper';

export default function BooksCarousel() {
  const [books, setBooks] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const token = `eb8LN6ph0zPMZF-BWZf1G4ldCRJp1BOebr4luAldBTM`;
      const client = createClient({
        // This is the space ID. A space is like a project folder in Contentful terms
        space: 's8z3hn4hritd',
        // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
        accessToken: token,
      });
      client
        .getEntries({
          content_type: 'book',
          order: 'fields.order',
        })
        .then((response) => {
          setBooks(response.items);
        });
    };

    fetch();
  }, []);

  return (
    <div className="books-carousel__wrapper">
      <div className="spacer" style={{ backgroundColor: '#000' }} />

      <section className="guides">
        <Fade className="swiper-container">
          <div className="swiper-wrapper">
            {!books.length ? null : (
              <Swiper
                onSwiper={(e) => setActiveIndex(e.activeIndex % books.length)}
                onSlideChange={(e) =>
                  setActiveIndex(e.activeIndex % books.length)
                }
                grabCursor={true}
                effect={'coverflow'}
                spaceBetween={5}
                slidesPerView={'auto'}
                loop={true}
                lazyOptions={{
                  loadingClass: 'books__carousel--loading',
                }}
                centeredSlides={true}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 200,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={{
                  el: '.swiper-pagination',
                }}
                modules={[EffectCoverflow, Pagination]}
              >
                {books.map((data, slideIdx) => {
                  const book = {
                    ...data.fields,
                    contentful_id: data.sys.id,
                  };

                  const cursor = activeIndex === slideIdx ? 'pointer' : 'grab';

                  return (
                    <SwiperSlide key={data.sys.id}>
                      <Book {...book} cursor={cursor} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </Fade>
      </section>
    </div>
  );
}
