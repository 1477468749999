import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/Hero';
import Slideshow from '../components/Slideshow';
import BodyParagraphs from '../components/BodyParagraphs';
import RotatingBook from './../components/RotatingBook';
import MailingListSignup from '../components/MailingListSignup';
import SlideText from './../components/SlideText';
import { graphql } from 'gatsby';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

const IndexPage = ({ data }) => {
  const {
    allContentfulGalleryImage: { nodes: images },
    allContentfulBodyText: { nodes: paragraphs },
    contentfulRotatingBook,
  } = data;

  return (
    <Layout>
      <ScrollToTopOnMount />
      <Hero backgroundColor="#000" />
      <BodyParagraphs nodes={paragraphs} />
      <Slideshow backgroundColor="#000" images={images} />
      <MailingListSignup backgroundColor="#ededed" />

      <RotatingBook contentfulRotatingBook={contentfulRotatingBook} />

      <div style={{ position: 'relative', height: '48px' }}>
        <SlideText />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulGalleryImage(sort: { fields: order, order: ASC }) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          description
        }
      }
    }
    allContentfulBodyText(sort: { fields: order, order: ASC }) {
      nodes {
        id
        textContent
        order
      }
    }
    contentfulRotatingBook {
      id
      thumbnail {
        url
      }
      spine {
        url
      }
      backImage {
        url
      }
    }
  }
`;
/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="THE OLITA GUIDE" />;

export default IndexPage;
