import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
  query {
    contentfulSlidingText {
      id
      textContent {
        textContent
      }
    }
  }
`;

function SlideText() {
  const {
    contentfulSlidingText: { textContent: item },
  } = useStaticQuery(query);

  return (
    <p className="marquee">
      <span>{item.textContent.repeat(50)}</span>
    </p>
  );
}

export default SlideText;
