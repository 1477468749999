import React, { useState } from 'react';
import ReactTypingEffect from 'react-typing-effect';
import styled from 'styled-components';
import useClickAwayListener from '../hooks/useClickAwayListener.hook';
import handleViewPort from 'react-in-viewport';

function MailingListSignup({
  backgroundColor = '#fff',
  inViewport,
  forwardedRef,
}) {
  const [value, setValue] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const ref = useClickAwayListener(
    (e) => {
      if (isTyping && !value) {
        return setIsTyping(false);
      }
      return setIsTyping(true);
    },
    [isTyping, value]
  );

  return (
    <Wrapper backgroundColor={backgroundColor} ref={forwardedRef}>
      <form
        action="https://f6414f95.sibforms.com/serve/MUIEAB_W2DID2UnnVN4rorWdTSvcgpAfRqmfPvO5hY1Zce6YGyNuxdsOZuNFKyy426PHjdCdgdDGzFpGGa8-JroKpDCWgDRyy0IWQg65ESkq7HFz20i0PvclAVypWbLr3zv3HiHkvuTqDuo8ZQYY6aR99jLLYv8r7DAccGF-oeDgyh1a-LNs3NlXURVYZky9HF1N7k-y-WRc8Npp"
        method="POST"
      >
        <Button type="submit">JOIN MAILING LIST</Button>
        <div
          id="typefx"
          onClick={() => {
            setTimeout(() => {
              setIsTyping(true);
            }, 50);
          }}
          ref={ref}
        >
          {isTyping ? (
            <div className="input__container">
              {/* <div id="blink" /> */}
              <Input
                type="email"
                id="EMAIL"
                name="EMAIL"
                autocomplete="off"
                required
                autoFocus={inViewport}
                data-required="true"
                onChange={(event) => setValue(event.target.value)}
              />
            </div>
          ) : (
            <ReactTypingEffect
              className="type-effect"
              typingDelay={800}
              eraseDelay={800}
              text={['TYPE YOUR EMAIL']}
            />
          )}
        </div>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  .input__container {
    position: relative;
  }
  @keyframes blinking {
    0% {
      background: #000;
    }
    100% {
      background: transparent;
    }
  }

  background-color: ${(props) => props.backgroundColor};
  color: #000;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 1100px;
  }
  @media screen and (max-width: 900px) {
    form {
      flex-direction: column-reverse;
    }
  }

  #typefx {
    display: block;
    color: #999;
    flex: 0.5;
    margin-left: 40px;
    /* width: 100%; */

    .type-effect {
      font-family: helvetica;
      font-weight: 400;
      font-size: 2.8em;
      color: #000;
    }

    @media screen and (max-width: 900px) {
      min-height: 68px;
      margin-left: 0;
      text-align: center;

      .type-effect {
        font-size: 2.4em;
      }
    }
  }
`;

const Input = styled.input`
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-width: 4px;
  border-radius: 4px;
  color: #000;
  width: 100%;
  font-family: helvetica;
  font-weight: 400;
  font-size: 2.8em;
  background-color: transparent;
  caret-color: #999 !important;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #999 !important;
  }

  @media screen and (max-width: 900px) {
    font-size: 36px;
    text-align: center;
    width: 99%;

    &::placeholder {
      text-align: left !important;
    }
  }
`;

const Button = styled.button`
  text-align: right;
  font-family: helvetica;
  font-weight: 400;
  font-size: 2.8em;
  color: #000;
  background-color: transparent;
  margin: auto 0px;
  border: none;
  /* padding-left: 25px; */
  /* padding-right: 25px; */
  flex: 0.5;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 900px) {
    font-size: 2.3em;
    text-align: center;
  }
`;
export default handleViewPort(MailingListSignup);
