/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import Footer from './Footer';

import './layout.css';
import 'normalize.css'; // npm package

const Layout = ({ children }) => {
  return (
    <>
      <div className="layout">
        <main className="layout__children">{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
