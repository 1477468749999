import React from 'react';
import './RotatingBook.css';
import { useLayoutEffect, useRef } from 'react';

function RotatingBook({ backgroundColor = '#000', contentfulRotatingBook }) {
  const ref = useRef();
  const { backImage, spine, thumbnail } = contentfulRotatingBook;

  useLayoutEffect(() => {
    ref.current?.style.setProperty('--book-front', `url(${thumbnail.url})`);
    ref.current?.style.setProperty('--book-spine', `url(${spine.url})`);
    ref.current?.style.setProperty('--book-back', `url(${backImage.url})`);

    // eslint-disable-next-line
  }, [ref.current]);

  if (!contentfulRotatingBook) return null;

  return (
    <section className="book-book" ref={ref} style={{ backgroundColor }}>
      <div className="book__container" id="book1">
        <div className="boockup">
          <div className="book-container">
            <div className="book-front"></div>

            <div className="book-side-left"></div>

            <div className="book-side-right"></div>

            <div className="book-top"></div>

            <div className="book-bottom"></div>

            <div className="book-back"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RotatingBook;
